/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-collapse";
import { PulseLoader } from "react-spinners";
import AddToCalendar from "react-add-to-calendar";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";

import clinicas from "../data/clinica";

import styles from "../css/ConfirmacionStyles.module.css";
import stylesDatos from "../css/ConfirmacionCitaPromo2x1Styles.module.css";
import stylesBoton from "../components/BotonGeneral/BotonGeneral.module.css";
import estilos from "../css/Style.module.css";

import { GoogleApiWrapper as MapaSucursales } from "../components/MapaSucursales";
import ComoLlego from "../components/ComoLlego";
import BotonGeneral from "../components/BotonGeneral";
import AvisoPago from "../components/AvisoPago/AvisoPago";
import Pdfconfirmacion from "./PdfConfirmacion";

import vectorLogo from "../images/svg/LogoSU.svg";
import imgTitlePromo2x1 from "../images/png/Promo2x1.png";
import imagen_Aviso from "../images/svg/CancelarModal.svg";
import Mascota from "../images/svg/MartinAqua.svg";
import LogoClinicasegura from "images/svg/confirmacion_logoClinicaSegura.svg";
import Festeja from "images/svg/confirmacion__Festeja.svg";
import Admiracion from "images/svg/confirmacion__admiracion.svg";

import DatosPromo2x1 from "./ConfirmacionPromo2x1/DatosPromo2x1";

const TXT_TITULO_SINGULAR = "Tu doctor de los ojos";
const TXT_TITULO_PLURAL = "Tu doctor de los ojos";
const TXT_SUBTITULO_SINGULAR = "Nuestro especialista te esta esperando";
const TXT_SUBTITULO_PLURAL = "Nuestro especialista los esta esperando";
const TXT_TIPO_CITA = "Tipo de Servicio Agendado:";
const TXT_COSTO = "Costo de la Cita:";
const TXT_NOMBRE = "Nombre Paciente:";
const TXT_NOMBRE_ACMP = "Nombre del acompañante:";
const TXT_EMAIL = "Email:";
const TXT_TELEFONO = "Teléfono de Contacto:";
const TXT_FECHA_HORA = "Fecha:";
const TXT_CLINICA = "Clínica seleccionada:";
const TXT_MODAL_CANCEL = "¿Estás seguro que quieres cancelar tu cita?";
const TXT_NOTA1 =
  "Te pedimos disponer al menos de 90 minutos. En caso de usar, debes traer tus lentes o lentes de contacto actuales. Cómo es posible que se te dilaten los ojos, te recomendamos venir acompañado solo de un adulto.";

const URL_CANCEL = "cita/Cancelada";
const URL_REAGENDAR = "reagendar";

let BOTONMODIFICAR = "";

let calEventName = null;
let calEventDesc = null;
let citaCancelada = false;

let detalle_Cita = null;

const params = new URLSearchParams(window.location.search);
let medio = params.get("medio");
let cmp = params.get("cmp");

export default class Confirmacion extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Confirmación] History`,
        value: props.location.state,
        important: true,
      });
    }
    console.log("[Confirmación] History:", props.location.state);
    //COMBAK
    //const baseUrl = window.location.hostname === 'citas.salauno.com.mx' ? 'https://agenda.salauno.com.mx/salauno' : 'https://salauno-agenda-new-testing-agenda.azurewebsites.net/salauno'
    const baseUrl =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://agenda.salauno.com.mx/salauno"
        : "https://salauno-agenda-new-erika.azurewebsites.net/salauno";

    this.scrollToMap = React.createRef();

    const { clinica, infoPx, para, cita, gdlvCampaign } = props.location.state;

    const paciente2 =
      para.paraQuien === 3
        ? `${infoPx[1].paciente2} ${infoPx[1].apellidoP2} ${infoPx[1].apellidoM2}`
        : "";
    const titulo =
      para.paraQuien === 1 ? TXT_TITULO_SINGULAR : TXT_TITULO_PLURAL;
    const subtitulo =
      para.paraQuien === 1 ? TXT_SUBTITULO_SINGULAR : TXT_SUBTITULO_PLURAL;

    // Verificar si cuenta con PlanDePagos.
    const { planDePagos } = clinicas[clinica.id];

    detalle_Cita = [
      {
        etiqueta: TXT_FECHA_HORA,
        info: `${cita.diaSemana} ${cita.diaMes} de ${cita.mes}`,
      },
      {
        etiqueta: "Hora:",
        info: `${cita.horario === "PM" ? "Por la Tarde" : "Por la Mañana"}, ${
          cita.horaCita
        } ${cita.horario === "PM" ? "pm" : "am"}`,
      },
      {
        etiqueta: TXT_CLINICA,
        info: clinica.nombreClinica,
      },
      {
        etiqueta: TXT_NOMBRE,
        info: `${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`,
      },
      {
        etiqueta: TXT_TELEFONO,
        info: `${infoPx[2].telefono}`,
      },
      {
        etiqueta: TXT_EMAIL,
        info: `${infoPx[2].email}`,
      },
      {
        etiqueta: TXT_TIPO_CITA,
        info: this.getServicio(),
      },
      {
        etiqueta: TXT_COSTO,
        info: this.getPrecio(),
      },
      {
        etiqueta: TXT_NOMBRE_ACMP,
        info: paciente2,
      },
      // {
      //   etiqueta: DIRECCION,
      //   info: clinica.direccion,
      // },
    ];

    this.state = {
      width: window.innerWidth,
      modal: false,
      showSeccionMapa: false,
      loadingCancel: false,
      titulo,
      subtitulo,
      planDePagos,
      baseUrl,
      gdlvCampaign,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.scrollTo(0, 0);
    if (citaCancelada) {
      window.location.reload(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  cancelarCita = () => {
    this.setState({ loadingCancel: true });
    const { baseUrl } = this.state;
    const { location, history } = this.props;
    const { state } = location;
    let { para, header, cita, historialFlujo } = state;
    historialFlujo.flujo1 = [];
    historialFlujo.flujoStep = 0;
    let newHistory = { ...state, header, historialFlujo };

    if (para.paraQuien === 3) {
      const cancelData = { id: cita.response1.id };
      const cancelDataAcmp = { id: cita.response2.id };

      if (process.env.NODE_ENV !== "production") {
        console.tron.log(
          `[Confirmacion] cancelData: ${JSON.stringify(cancelData)}`
        );
      }

      axios
        .post(`${baseUrl}/Cancel`, cancelData)
        .then((responseCancel) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Cancel response 1: ${JSON.stringify(
                responseCancel.data
              )}`
            );
          }
        })
        .catch((errorCancel) => {
          console.error(
            `[Confirmacion] Error al cancelar primera cita ${errorCancel}`
          );
          if (process.env.NODE_ENV !== "production") {
            console.tron.error(
              `[Confirmacion] Error al cancelar primera cita ${errorCancel}`
            );
          }
        });

      axios
        .post(`${baseUrl}/Cancel`, cancelDataAcmp)
        .then((responseCancelAcmp) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Cancel response 2: ${JSON.stringify(
                responseCancelAcmp.data
              )}`
            );
          }
          citaCancelada = true;
          history.push(`/${URL_CANCEL}`, newHistory);
        })
        .catch((errorCancelAcmp) => {
          console.error(
            `[Confirmacion] Error al cancelar segunda cita ${errorCancelAcmp}`
          );
          if (process.env.NODE_ENV !== "production") {
            console.tron.error(
              `[Confirmacion] Error al cancelar segunda cita ${errorCancelAcmp}`
            );
          }
        });
    } else {
      const cancelData = { id: cita.response.id };

      if (process.env.NODE_ENV !== "production") {
        console.tron.log(
          `[Confirmacion] Cancel Post ${JSON.stringify(cancelData)}`
        );
      }
      axios
        .post(`${baseUrl}/Cancel`, cancelData)
        .then((response) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Cita cancelada. Cancel response: ${JSON.stringify(
                response.data
              )}`
            );
          }
          citaCancelada = true;
          history.push(`/${URL_CANCEL}`, newHistory);
        })
        .catch((errorCancel) => {
          console.error(`[Confirmacion] Error al cancelar cita ${errorCancel}`);
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Error al cancelar cita ${errorCancel}`
            );
          }
        });
    }
  };

  reagendarCita = () => {
    const { location, history } = this.props;

    const newHistory = {
      ...location.state,
      reagendar: true,
    };

    history.push(`/${URL_REAGENDAR}${history.location.search}`, newHistory);
  };

  getDay = () => {
    const { para, cita } = this.props.location.state;
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let datetime = null;

    if (para.paraQuien !== 3) {
      datetime = cita.dateTime;
    } else {
      datetime = cita.datetime1;
    }

    const mes = datetime.substring(5, 7);
    const dia = datetime.substring(8, 10);
    const year = datetime.substring(0, 4);
    const mesTexto = meses[parseInt(mes, 10) - 1];

    return `${dia} ${mesTexto}, ${year}`;
  };

  getHour = () => {
    const { para, cita } = this.props.location.state;

    if (para.paraQuien !== 3) {
      const hora = cita.dateTime.substring(11, 16);
      return hora;
    }
    const hora1 = cita.datetime1.substring(11, 16);
    const hora2 = cita.datetime2.substring(11, 16);
    return `${hora1} y ${hora2}`;
  };

  /* getFechaHora = () => {
    const {
      header,
      serviceId,
    } = this.props.location.state

    if ((serviceId === 1747562) || (serviceId === 1748018) || (serviceId === 2719476) || (serviceId === 2389968) || (serviceId === 1803491) || (medio === 'GDLV') || (cmp === 'C-MKT-LASIK-001')) {
      return header[2].dato
    }
    return header[3].dato
  } */

  getServicio = () => {
    const { header } = this.props.location.state;
    let serviceName = null;
    switch (header[0].diagnostico) {
      case "Actualización de lentes":
        serviceName = "Consulta con médico optometrista";
        break;
      case "Problemas de retina":
        serviceName = "Consulta Oftalmólogo Especialista en Retina";
        break;
      case "Cataratas":
        serviceName = "Consulta Oftalmólogo General";
        break;
      case "Glaucoma":
        serviceName = "Consulta Oftalmólogo Especialista en Glaucoma";
        break;
      case "Estrabismo (ojo desviado)":
        serviceName = "No se cuenta con el servicio";
        break;
      case "Problema de párpados y vías lagrimales":
        serviceName = "No se cuenta con el servicio";
        break;
      case "Córnea":
        serviceName = "Consulta Oftalmólogo Especialista en Córnea";
        break;
      case "Miopía,hipermetropía,astigmatismo":
        serviceName = "Consulta Oftalmólogo General";
        break;
      case "Tengo dudas sobre mi diagnóstico":
        serviceName = "Consulta Oftalmólogo General";
        break;
      case "No se resolvió tu problema en la orientación previa":
        serviceName = "Consulta Oftalmólogo General";
        break;
      default:
        serviceName = "";
        calEventName = "";
        calEventDesc = "";
        break;
    }
    return serviceName;
  };

  getPrecio = () => {
    const { serviceId, header, cita } = this.props.location.state;
    console.log('cita: ',cita);
    console.log('Servicio : ', serviceId);
    console.log('Medio : ', medio);

    if (cmp === "C-MKT-LASIK-001" && serviceId === 1748018) {
      return `$ 0.00 MXN`;
    }

    if (medio === "GDLV" || medio === "GDLV-P" || medio === "CROWNBACARRA") {
      return `$ ${cita.response.price} MXN`;
    }

    if (medio === 'Metro'  && serviceId ==! 1747562) {
      return `$ ${cita.response.price} MXN`;
    }

    if (medio === 'Metro' && serviceId === 1747562) {
      return `$ 499.00 MXN`;
    }
    if (cita.response1) {
      // Cuando es promocion 2x1
      return `$ ${cita.response1.price} MXN`;
    }
    if (cita.response2) {
      return `$ ${cita.response2.price} MXN`;
    }
    if(cita.response.price === undefined || cita.response.price === 'undefined' || cita.response.price === null){
      return `$ 0.00 MXN`;
    }
    //si el medio=azucar y el servicio es 20478366 el precio debe ser 0.00
    if (medio === "Azucar" && serviceId === 20478366) {
      return `$ 0.00 MXN`;
    }

    return `$ ${cita.response.price} MXN`;
  };

  showMap = () => {
    const { showSeccionMapa } = this.state;
    this.setState({
      showSeccionMapa: !showSeccionMapa,
    });

    if (!showSeccionMapa) {
      setTimeout(() => {
        window.scroll({
          left: 0,
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  toggleModalCancel = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const {
      modal,
      showSeccionMapa,
      loadingCancel,
      titulo,
      subtitulo,
      gdlvCampaign,
    } = this.state;
    console.log("gdlvCampaign:", gdlvCampaign);
    const { location, className } = this.props;
    const { infoPx, clinica, datetime, para, cita } = location.state;

    let calEvent = "";
    const endDatetime = new Date(datetime);
    endDatetime.setTime(endDatetime.getTime() + 90 * 60 * 1000);

    if (para.paraQuien !== 3) {
      if (calEventName === "Valoración") {
        calEventDesc +=
          " NOTA: Como es posible que te dilaten los ojos te recomendamos venir acompañado.";
      }
      calEvent = {
        title: calEventName,
        description: calEventDesc,
        location: clinica.direccion,
        startTime: datetime,
        endTime: endDatetime,
      };
    } else {
      if (calEventName === "Consulta Oftalmológica") {
        calEventName = `2x1 ${calEventName}`;
        calEventDesc +=
          " NOTA: Recuerda que si no vienes con tu acompañante o si alguno de los dos ya ha venido a salauno, no les podremos aplicar la promoción.";
      } else {
        calEventName += " (Para Ti y Para Alguien Más)";
      }

      const endDatetimeDoble = new Date(cita.dateTime2);
      endDatetimeDoble.setTime(endDatetimeDoble.getTime() + 90 * 60 * 1000);
      calEvent = {
        title: calEventName,
        description: calEventDesc,
        location: clinica.direccion,
        startTime: cita.dateTime1,
        endTime: endDatetimeDoble,
      };
    }

    const calItems = [
      { google: "Google" },
      { apple: "iCal" },
      { outlook: "Outlook" },
      { outlookcom: "Outlook.com" },
      { yahoo: "Yahoo" },
    ];
    const calIcon = { "calendar-plus-o": "left" };

    if (cita.paraQuien !== 3) {
      BOTONMODIFICAR = (
        <button
          className={styles.BotonConfirmar}
          onClick={this.reagendarCita}
          type="button"
        >
          {"MODIFICAR"}
        </button>
      );
    }

    const paciente2 =
      cita.paraQuien === 3
        ? `${infoPx[1].paciente2} ${infoPx[1].apellidoP2} ${infoPx[1].apellidoM2}`
        : "";
    return this.props.location.state.para.paraQuien !== 3 ? (
      <Container fluid className={`${styles.containerConfirmacion}`}>
        <Container className={`${styles.confirmacionContainer}`}>
          <Row className={`${styles.rowContainer}`}>
            <Col xs={12} className={`${styles.colContainer}`}>
              <Row className={`${styles.rowContainerInfo}`}>
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colContainerInfoPaciente}`}
                >
                  <Row className={`${styles.rowContainerInfoPaciente}`}>
                    <Col
                      xs={12}
                      className={`${styles.colContainerIconInfoPaciente}`}
                    >
                      <img
                        src={Festeja}
                        className={`${styles.colContainerIconInfoPaciente__icon}`}
                        alt="icon_festeja"
                      />
                    </Col>
                    <Col
                      xs={12}
                      className={`${styles.colContainerTitleInfoPaciente}`}
                    >
                      <p
                        className={`${styles.colContainerTitleInfoPaciente__title}`}
                      >
                        ¡Tu cita quedó agendada con éxito!
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      className={`${styles.colContainerSubTitleInfoPaciente}`}
                    >
                      <p
                        className={`${styles.colContainerSubTitleInfoPaciente__title}`}
                      >
                        Tu doctor de los ojos te está esperando.
                      </p>
                    </Col>
                    <Col xs={12} className={`${styles.colContainerInfoCita}`}>
                      <Row className={`${styles.rowContainerInfoCita}`}>
                        {detalle_Cita.map((obj) => {
                          if (
                            obj.info !== "" &&
                            obj.info !== "sincorreo@salauno.com.mx"
                          ) {
                            return (
                              <Col
                                xs={12}
                                className={`${styles.colContainerEtiquetas}`}
                              >
                                <p
                                  className={`${styles.colContainerEtiquetas__etiqueta}`}
                                >{`${obj.etiqueta} ${obj.info}`}</p>
                              </Col>
                            );
                          }
                          return null;
                        })}
                      </Row>
                      <Row className={`${styles.rowContainerInfoCita}`}>
                        {this.props.location.state.urlParams.medio ===
                        "GDLV-P" ? (
                          <Col
                            xs={12}
                            className={`${styles.colContainerEtiquetas}`}
                          >
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              * En caso de ser un paciente mayor a 60 años la
                              consulta de primera vez sera gratuita
                            </p>
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              * En caso de ser un paciente menor de 60 años la
                              consulta de primera vez sera de 199 pesos
                            </p>
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              * En caso de ser una consulta subsecuente el costo
                              sera de 300 pesos
                            </p>
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              * En caso de ser una consulta de especialidad el
                              costo sera de 400 pesos
                            </p>
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              Codigo de guardian :{gdlvCampaign}
                            </p>
                          </Col>
                        ) : null}

                        {this.props.location.state.urlParams.medio ===
                        "GDLV" ? (
                          <Col
                            xs={12}
                            className={`${styles.colContainerEtiquetas}`}
                          >
                            <p
                              className={`${styles.colContainerEtiquetas__etiqueta}`}
                            >
                              Codigo de guardian :
                              {this.props.location.state.urlParams.utm_campaign}
                            </p>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      className={`${styles.colContainerButtonsCancModif}`}
                    >
                      <Row className={`${styles.rowContainerButtonsCancModf}`}>
                        <Col
                          xs={6}
                          className={`${styles.colContainerButtonCancelar}`}
                        >
                          <button
                            className={styles.BotonCancelar}
                            onClick={this.toggleModalCancel}
                            type="button"
                          >
                            {"CANCELAR"}
                          </button>
                        </Col>
                        <Col
                          xs={6}
                          className={`${styles.colContainerButtonModificar}`}
                        >
                          {BOTONMODIFICAR}
                        </Col>
                      </Row>
                      <Row className={`${styles.rowContainerSeparador}`}>
                        <Col
                          xs={12}
                          className={`${styles.colContainerSeparador} `}
                        >
                          <div
                            className={`${styles.colContainerSeparador__separador}`}
                          />
                        </Col>
                      </Row>
                      <Row className={`${styles.rowContainerButtonPdf}`}>
                        <Col
                          xs={12}
                          className={`${styles.colContainerButtonPdf} d-flex  align-items-center`}
                        >
                          <Pdfconfirmacion
                            nombre={`${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`}
                            nombre2={paciente2}
                            hora={this.getHour()}
                            fecha={this.getDay()}
                            sucursal={clinica.nombreClinica}
                            tipo={this.getServicio()}
                            comoLlegar={clinica.como}
                            direccion={clinica.direccion}
                            buttonLabel="DESCARGAR COMPROBANTE"
                          />
                        </Col>
                      </Row>
                      <Row
                        className={`${styles.rowContainerButtonAgregarCalendar}`}
                      >
                        <Col
                          xs={12}
                          className={`${styles.colContainerButtonAgregarCalendar} d-flex align-items-center`}
                        >
                          <button
                            className={
                              stylesBoton.BotonGeneralActivo_blancoPresencial
                            }
                            type="button"
                          >
                            <AddToCalendar
                              event={calEvent}
                              buttonTemplate={calIcon}
                              buttonLabel="Agregar a mi calendario"
                              buttonClassClosed={
                                estilos.reactAddToCalendarButton
                              }
                              useFontAwesomeIcons
                              buttonWrapperClass={
                                estilos.reactAddToCalendarWrapper
                              }
                              displayItemIcons
                              dropdownClass={estilos.reactAddToCalendarDropdown}
                              listItems={calItems}
                              rootClass={estilos.reactAddToCalendar}
                            />
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colContainerInfoClinica} d-flex justify-content-center`}
                >
                  <Row className={`${styles.rowContainerInfoClinica}`}>
                    <Col
                      xs={12}
                      className={`${styles.colContainerConsideraciones}`}
                    >
                      <p
                        className={`${styles.colContainerConsideraciones__title}`}
                      >
                        <span>Consideraciones para</span>
                        <span> acudir a tu cita</span>
                      </p>
                      <p
                        className={`${styles.colContainerConsideraciones__consideraciones}`}
                      >
                        <p
                          className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                        >
                          En caso de utilizar anteojos o lentes de contacto,
                        </p>
                        <p
                          className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                        >
                          te pedimos los lleves contigo a tu consulta.
                        </p>
                        <p
                          className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                        >
                          Es posible que durante tu consulta se te dilaten las
                        </p>
                        <p
                          className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                        >
                          pupilas, te recomendamos asistir con un
                        </p>
                        <p
                          className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                        >
                          acompañante adulto.
                        </p>
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      className={`${styles.colContainerClinicaSegura}`}
                    >
                      <Row className={`${styles.rowContainerClinicaSegura}`}>
                        <Col
                          xs={12}
                          className={`${styles.colContainerImgClinicaSegura} d-flex justify-content-center`}
                        >
                          <img src={LogoClinicasegura} alt="icon_logo" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colContainerInfoClinicaSegura}`}
                        >
                          <p
                            className={`${styles.colContainerInfoClinicaSegura__p}`}
                          >
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              De acuerdo a la normatividad por la pandemia COVID
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              19 te pedimos seguir las indicaciones al llegar a
                              la
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              clínica, necesitamos de tu colaboración. ¡Te
                              cuidas tu,
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              nos cuidamos todos! Uso indispensable de
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              cubrebocas. Acudir acompañado de un solo adulto
                              (no
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              vulnerable). Mantén sana distancia. Te pedimos
                            </p>
                            <p
                              className={`${styles.colContainerInfoClinicaSegura__lineaParrafo}`}
                            >
                              asistir con 15 min. de anticipación a tu consulta.
                            </p>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Modal
                isOpen={modal}
                toggle={this.toggleModalCancel}
                className={styles.modalContainer}
                id="modal_cancelarCita"
                backdropClassName={`${styles.backdropModal}`}
                modalClassName={`${styles.modal}`}
                contentClassName={`${styles.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleModalCancel}
                  className={`${styles.modalHeader}`}
                  buttonClassClosed={`${styles.modalButtonClosed}`}
                />
                <ModalBody className={`${styles.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p className={styles.textModalCancelar}>
                            {TXT_MODAL_CANCEL}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    {loadingCancel ? (
                      <Container>
                        <Row>
                          <Col xs={12}>
                            <div
                              className={`${styles.col_cancelarSi} d-flex justify-content-center`}
                            >
                              <PulseLoader
                                sizeUnit="px"
                                size={15}
                                margin="0"
                                color="#00748c"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    ) : (
                      <Container>
                        <Row>
                          <Col
                            className={`${styles.col_cancelarSi} d-flex justify-content-center`}
                            xs={12}
                          >
                            <button
                              onClick={this.cancelarCita}
                              className={`${styles.col_cancelarSiButton}`}
                            >
                              SI, CANCELAR MI CITA
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Container>
    ) : (
      //! aqui comienza la confirmacion para promo 2x1 COMBAK
      <div>
        <Container fluid className={styles.background}>
          <Row className={styles.row_Confirmacion}>
            <Col className={styles.col_logotipo} xs="12">
              <img
                src={vectorLogo}
                className={styles.img_logotipo}
                alt="salauno Clinicas Oftalmológicas"
              />
              <hr className={styles.hr_logotipo}></hr>
            </Col>
            <Col
              className={styles.col_imagenPrincipal}
              lg="3"
              md="3"
              sm="3"
              xs="12"
            >
              <center>
                <img
                  src={imgTitlePromo2x1}
                  className={styles.img_Principal_Doctor}
                  alt=""
                />
              </center>
            </Col>
            <Col lg="9" md="9" sm="9" xs="12">
              <h2 className={styles.titulo_blanco}>{titulo}</h2>
              <h3 className={styles.subtitulo_azul}>{subtitulo}</h3>
            </Col>
          </Row>
          <Row className={styles.box_DetalleCita}>
            <DatosPromo2x1 state={this.props.location.state} />
            <Col className={styles.row_DetalleCita} xs="12">
              {/* //TODO cambiar imagen por el componente aviso pago */}
              <AvisoPago img={Mascota}>{TXT_NOTA1}</AvisoPago>
            </Col>
          </Row>
          <Row className={`${stylesDatos.rowContainerIncluye} py-5 px-5`}>
            <Col
              className={`${stylesDatos.rowContainerIncluye__colTileIncluye} d-flex justify-content-center`}
              xs={12}
            >
              <h2 className={`${stylesDatos.rowContainerIncluye__title}`}>
                La promoción aplica en consulta adulto, incluye:
              </h2>
            </Col>
            <Col
              className={`${stylesDatos.rowContainerIncluye__colIncluye} d-flex justify-content-center`}
              xs={12}
            >
              <ul className={`${stylesDatos.rowContainerIncluye__ul}`}>
                <li className={`${stylesDatos.rowContainerIncluye__li}`}>
                  Tonómetro (toma de presión oculaer)
                </li>
                <li className={`${stylesDatos.rowContainerIncluye__li}`}>
                  Autorefractomretro (examen computarizado)
                </li>
                <li className={`${stylesDatos.rowContainerIncluye__li}`}>
                  Revisión con el optometrista (agudeza visual, retinoscopia,
                  refracción y prueba ambulatoria)
                </li>
              </ul>
            </Col>
          </Row>
          <Row className={`${stylesDatos.rowContainerRestricciones}`}>
            <Col
              className={`${stylesDatos.rowContainerRestricciones__colRestricciones} py-2 d-flex justify-content-center`}
              xs={12}
            >
              <ul className={`${stylesDatos.rowContainerRestricciones__ul}`}>
                <h3 className={`${stylesDatos.rowContainerRestricciones__h3}`}>
                  Restricciones:
                </h3>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  Aplica en consulta general de primera vez y solo para personas
                  mayores de 60 años.
                </li>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  Deberán agendar cita previamente para poder obtener la
                  promoción
                </li>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  La cita debe programarse para ambos pacientes el mismo día
                </li>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  Ambos pacientes deberán acudir en el mismo día de la cita
                </li>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  Válido solo en Centro Quirúrgico Hamburgo
                </li>
                <li className={`${stylesDatos.rowContainerRestricciones__li}`}>
                  Vigencia al 31 de agosto del 2020
                </li>
              </ul>
            </Col>
          </Row>
          <Row className={styles.rowBotones}>
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <center>
                <Pdfconfirmacion
                  nombre={`${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`}
                  nombre2={paciente2}
                  hora={this.getHour()}
                  fecha={this.getDay()}
                  sucursal={clinica.nombreClinica}
                  tipo={this.getServicio()}
                  comoLlegar={clinica.como}
                  direccion={clinica.direccion}
                  buttonLabel="Descargar comprobante"
                />
              </center>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <center>
                <button
                  className={stylesBoton.BotonGeneralActivo_blancoPresencial}
                  type="button"
                >
                  <AddToCalendar
                    event={calEvent}
                    buttonTemplate={calIcon}
                    buttonLabel="Añadir al calendario"
                    buttonClassClosed={estilos.reactAddToCalendarButton}
                    useFontAwesomeIcons
                    buttonWrapperClass={estilos.reactAddToCalendarWrapper}
                    displayItemIcons
                    dropdownClass={estilos.reactAddToCalendarDropdown}
                    listItems={calItems}
                    rootClass={estilos.reactAddToCalendar}
                  />
                </button>
              </center>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4">
              <center>
                <button
                  className={stylesBoton.BotonGeneralActivo_blancoPresencial}
                  type="button"
                  onClick={this.showMap}
                  buttonLabel="¿Cómo llego?"
                >
                  <p className={`${styles.p_comoLLego}`}>
                    {"¿Cómo llego?"}
                    <i className={styles.colBotonModificar} />
                  </p>
                </button>
              </center>
            </Col>
          </Row>
          <Collapse isOpened={showSeccionMapa}>
            <Row className={styles.rowMapaConfirmacion} ref={this.scrollToMap}>
              <Col
                md={{ size: 6 }}
                sm={{ size: 12 }}
                xs={{ size: 11 }}
                lg={{ size: 8 }}
                xl={{ size: 8 }}
                className={styles.contenedorMapaConfirmacion}
              >
                <MapaSucursales
                  coords={clinica.coordClinica}
                  zoom={18}
                  showMartin={false}
                />
              </Col>
              <Col
                md={{ size: 6 }}
                sm={{ size: 12 }}
                xs={{ size: 12 }}
                lg={{ size: 4 }}
                xl={{ size: 4 }}
                className={styles.comoLlegoConfirmacion}
              >
                <ComoLlego clinicKey={clinica.id} />
              </Col>
            </Row>
          </Collapse>
          <Row className={styles.row_Confirmacion}>
            {/* //TODO aqui se cambia la segunda imagen por compoente */}
            {/* <Col>
              <img
                src={imagen_Indicaciones}
                className={styles.img_Indicaciones}
                alt=""
              />
            </Col> */}
          </Row>
          <Row className={styles.botones}>
            {BOTONMODIFICAR}
            <Col
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              className={styles.colBotonCancelar}
            >
              <button
                className={styles.BotonCancelar}
                onClick={this.toggleModalCancel}
                type="button"
              >
                {"Cancelar"}
              </button>
            </Col>
          </Row>
        </Container>
        <div>
          <Modal
            isOpen={modal}
            toggle={this.toggleModalCancel}
            className={className}
            id="modal_cancelarCita"
          >
            <ModalHeader toggle={this.toggleModalCancel} />
            <ModalBody className={`${styles.cancelar_modalBody}`}>
              <>
                <div className={styles.imgModal}>
                  <img src={imagen_Aviso} alt="" />
                </div>
                <br />
                <p className={styles.textModalCancelar}>{TXT_MODAL_CANCEL}</p>
                {loadingCancel ? (
                  <div
                    className={`${estilos.sweetLoadingCancelar} ${styles.row_botonCancelacion} row`}
                  >
                    <PulseLoader
                      sizeUnit="px"
                      size={15}
                      margin="0"
                      color="#00748c"
                    />
                  </div>
                ) : (
                  <div className={`${styles.row_botonCancelacion} row`}>
                    <Col
                      className={`${styles.col_cancelarSi}`}
                      xs={{ size: 11 }}
                      sm={{ size: 11 }}
                      md={{ size: 6 }}
                      lg={{ size: 6 }}
                      xl={{ size: 6 }}
                    >
                      <BotonGeneral
                        manejarClick={this.cancelarCita}
                        nombreBoton="SÍ"
                        activo
                        existe
                      />
                    </Col>
                    <Col
                      className={`${styles.col_cancelarNo}`}
                      xs={{ size: 11 }}
                      sm={{ size: 11 }}
                      md={{ size: 6 }}
                      lg={{ size: 6 }}
                      xl={{ size: 6 }}
                    >
                      <BotonGeneral
                        manejarClick={this.toggleModalCancel}
                        nombreBoton="NO"
                        activo
                        existe
                      />
                    </Col>
                  </div>
                )}
              </>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

Confirmacion.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  className: PropTypes.any,
};

Confirmacion.defaultProps = {
  location: null,
  history: null,
  className: null,
};
